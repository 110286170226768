<template>
  <div style="align-items: center;padding: 50px 0;font-size: 30px;">404</div>
</template>

<script>
export default {
  name: "notFound"
}
</script>

<style scoped>

</style>